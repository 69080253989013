import React from 'react'
import { Icon } from '../Icon/Icon'

export type ProgressSpinnerProps = {
	containerClassName?: string
	className?: string
	size?: 'small' | 'medium' | 'large'
	isPage?: boolean
}

export const ProgressSpinner: React.FC<ProgressSpinnerProps> = ({ containerClassName, className, size, isPage = false }) => (
	<div className='flex flex-col w-full h-full fixed inset-0 items-center justify-center gap-3'>
		<Icon type='iconAnimation' className='w-10 h-10' />
		<div className=' text-white font-bold'>Loading..</div>
	</div>
	// <div
	//   className={`w-full h-full ${isPage ? 'fixed' : 'absolute'
	//     } inset-0 flex items-center justify-center z-10 ${containerClassName}`}
	// >
	//   <svg
	//     className={`animate-spin block ${(size === 'small' && 'h-5 w-5') || (size === 'large' && 'h-9 w-9') || 'h-6 w-6'
	//       } ${className}`}
	//     fill='none'
	//     viewBox='0 0 24 24'
	//   >
	//     <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
	//     <path
	//       className='opacity-75'
	//       fill='currentColor'
	//       d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
	//     ></path>
	//   </svg>
	// </div>
)
