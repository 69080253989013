export interface ListCompanyRns {
	paging: Paging
	items: Rns[]
}

export interface Paging {
	totalResults: number
	lastKey: {
		type: string
		sk: string
	}
}

export interface Rns {
	id: string
	date: string
	headline: string
	url: string
	selected: boolean
	generatedData: GeneratedData
	company: companyDetail
}

export interface companyDetail {
	industry: string
	marketCap: string
	marketCapDisp: string
	priceChangePercent: string
	sector: string
	ticker: string
}

export interface HomePageNews {
	news: Rns
	views: number
	weight: number
}

export interface GeneratedData {
	headline: string
	shortSummary: string
	longSummary: string
	bulletPoints: string[]
	sentiment: number
	sentimentScore?: 'UNKNOWN' | 'VERY_BAD' | 'BAD' | 'NEUTRAL' | 'GOOD' | 'VERY_GOOD'
	sentimentReasoning?: string
	companyDetail?: string
	keyDates: keyDate[]
	financials: string
	documentType: RnsDocumentTypes
}

export interface keyDate {
	date: string
	dateLabel: string
	description: string
}
export interface CompanyNewsDate {
	id: string
	headline: string
	date: string
	sentiment: 'UNKNOWN' | 'VERY_BAD' | 'BAD' | 'NEUTRAL' | 'GOOD' | 'VERY_GOOD' | undefined
}

export interface NewsFacets {
	totalItems: number
	facetCounts: FacetCounts
	sentimentCounts: SentimentCounts
	permutationCounts: PermutationCounts
}

export interface FacetCounts {
	LargeCap: number
	MidCap: number
	SmallCap: number
	MicroCap: number
}

export interface SentimentCounts {
	VERY_BAD: number
	BAD: number
	NEUTRAL: number
	GOOD: number
	VERY_GOOD: number
}

export interface PermutationCounts {
	large_cap_positive: number
	large_cap_all: number
	mid_cap_positive: number
	mid_cap_all: number
	large_cap_mid_cap_positive: number
	large_cap_mid_cap_all: number
	small_cap_positive: number
	small_cap_all: number
	large_cap_small_cap_positive: number
	large_cap_small_cap_all: number
	mid_cap_small_cap_positive: number
	mid_cap_small_cap_all: number
	large_cap_mid_cap_small_cap_positive: number
	large_cap_mid_cap_small_cap_all: number
	micro_cap_positive: number
	micro_cap_all: number
	large_cap_micro_cap_positive: number
	large_cap_micro_cap_all: number
	mid_cap_micro_cap_positive: number
	mid_cap_micro_cap_all: number
	large_cap_mid_cap_micro_cap_positive: number
	large_cap_mid_cap_micro_cap_all: number
	small_cap_micro_cap_positive: number
	small_cap_micro_cap_all: number
	large_cap_small_cap_micro_cap_positive: number
	large_cap_small_cap_micro_cap_all: number
	mid_cap_small_cap_micro_cap_positive: number
	mid_cap_small_cap_micro_cap_all: number
	large_cap_mid_cap_small_cap_micro_cap_positive: number
	large_cap_mid_cap_small_cap_micro_cap_all: number
}

export enum RnsDocumentTypes {
	TRADING_UPDATE = 'TRADING_UPDATE',
	HALF_YEAR = 'HALF_YEAR',
	ANNUAL_RESULTS = 'ANNUAL_RESULTS',
	Q1_UPDATE = 'Q1_UPDATE',
	TR1 = 'TR1',
	DIRECTOR_DEALINGS = 'DIRECTOR_DEALINGS',
	CONTRACT_WIN = 'CONTRACT_WIN',
	GENERAL_UPDATE = 'GENERAL_UPDATE',
	PLACING = 'PLACING',
}
