import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCompany, searchCompany } from '../../../services/company'
import { Icon } from '../../atoms/Icon/Icon'
import { Company } from '../../../types/company'
import { Cross, Spinner } from '../../atoms/Icons/Icons'

interface SearchResult {
	ticker: string
	name: string
}

interface SearchBarProps {
	onClick?: () => void
	className?: string
	mobileOpen?: boolean
	handleClose?: (open: boolean) => void
}

const SearchBar: React.FC<SearchBarProps> = ({ onClick, className, mobileOpen, handleClose }) => {
	const [search, setSearch] = useState<string>('')
	const [searchResults, setSearchResults] = useState<SearchResult[]>([])
	const [isFocused, setIsFocused] = useState<boolean>(false)
	const [previewCompany, setPreviewCompany] = useState<Company | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null)
	const resultsRef = useRef<HTMLDivElement | null>(null)
	const inputRef = useRef<HTMLInputElement>(null)
	const navigate = useNavigate()

	useEffect(() => {
		if (mobileOpen) {
			setIsFocused(true)
			inputRef.current?.focus()
		}
	}, [mobileOpen])

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (resultsRef.current && !resultsRef.current.contains(event.target as Node) && !inputRef.current?.contains(event.target as Node)) {
				setIsFocused(false)
				setSearchResults([])
				setPreviewCompany(null)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value
		setSearch(inputValue)

		if (debounceTimeoutRef.current) {
			clearTimeout(debounceTimeoutRef.current)
		}

		debounceTimeoutRef.current = setTimeout(() => {
			if (inputValue.trim().length >= 1) {
				searchStock(inputValue.trim())
			} else {
				setSearchResults([])
				setPreviewCompany(null)
				setIsLoading(false)
			}
		}, 200)
	}

	const searchStock = async (query: string) => {
		setIsLoading(true)
		setPreviewCompany(null)
		setSearchResults([])
		try {
			const res = await searchCompany(query)
			if (res.length === 1) {
				const company = await getCompany('lse', res[0].ticker)
				setPreviewCompany(company)
			} else {
				setSearchResults(res)
			}
		} catch (error) {
			console.error('Error fetching search results:', error)
			// Optionally set an error state here
		} finally {
			setIsLoading(false)
		}
	}

	const handleResultClick = (result: SearchResult) => {
		if (onClick) {
			onClick()
		}
		navigate(`/company/lse/${result.ticker.toLowerCase()}`)
		setSearch('')
		setPreviewCompany(null)
		setIsFocused(false)
		if (handleClose) {
			handleClose(false)
		}
	}

	const clearSearch = () => {
		setSearch('')
		setSearchResults([])
		setPreviewCompany(null)
		setIsLoading(false)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Escape') {
			clearSearch()
			inputRef.current?.blur()
		}
	}

	return (
		<div className={className}>
			<div className='relative text-white'>
				<div className='relative w-full'>
					<input
						ref={inputRef}
						className={`flex h-10 appearance-none outline-none bg-transparent w-full pl-10 pr-10 border text-white font-normal transition-all duration-500 z-0 ${
							isFocused ? 'border-blue-400/80 border-2 rounded-3xl' : 'rounded-md border-white/60'
						}`}
						autoComplete='off'
						placeholder='Search Company'
						value={search}
						name='searchbar'
						onChange={handleSearchChange}
						onKeyDown={handleKeyDown}
						onFocus={() => setIsFocused(true)}
					/>
					<div className='absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none'>
						<Icon type='search' className='text-white' />
					</div>
					{search && (
						<div className='absolute inset-y-0 right-0 flex items-center'>
							<button onClick={clearSearch} className='relative outline-none focus:outline-none items-center w-10 h-10' aria-label='Clear search'>
								{isLoading ? <Spinner className='w-5 h-5 mx-auto' /> : <Cross className='w-5 h-5 mx-auto' />}
							</button>
						</div>
					)}
				</div>
				{(isLoading || searchResults.length > 0 || previewCompany) && (
					<div className='absolute w-full top-full z-10 bg-gray-800 shadow-xl rounded-2xl p-2' ref={resultsRef}>
						{isLoading && (
							<div className='flex justify-center items-center p-4'>
								<Spinner className='w-6 h-6 text-white' />
							</div>
						)}
						{!isLoading && searchResults.length > 1 && (
							<>
								{searchResults.map((result, index) => (
									<button
										key={index}
										className='w-full text-left p-2 rounded-lg mb-1 hover:bg-gray-700 transition-colors font-semibold'
										onClick={() => handleResultClick(result)}
									>
										{result.name} ({result.ticker})
									</button>
								))}
							</>
						)}
						{!isLoading && previewCompany && (
							<button
								className='w-full text-left p-5 rounded-lg mb-1 bg-gray-700 hover:bg-gray-600 transition-colors font-semibold'
								onClick={() => handleResultClick({ name: previewCompany.name, ticker: previewCompany.ticker })}
							>
								<div className='flex flex-row gap-2 font-normal'>
									<div className='text-4xl'>{previewCompany.currentPrice}</div>
									<div className='text-lg'>{previewCompany.currency}</div>
								</div>
								<div className='flex flex-row gap-2 text-2xl font-normal pt-3'>
									<div className={`text-xl ${Number(previewCompany.currentPriceChange) < 0 ? 'text-red-400' : 'text-green-400'}`}>{previewCompany.currentPriceChange}</div>
									<div className={`text-xl ${Number(previewCompany.currentPriceChange) < 0 ? 'text-red-400' : 'text-green-400'}`}>
										({previewCompany.currentPriceChangePercent})
									</div>
								</div>
								<div className='flex flex-row gap-2 text-2xl font-normal pt-1 text-gray-300'>
									<div>{previewCompany.name}</div>
								</div>
							</button>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default SearchBar
