import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getHomePageNews, getNewsItems } from '../../services/rns'
import { getMarketPrices, getRisersFallers } from '../../services/company'
import { addBookmark, getUserBookmarks, removeBookmark } from '../../services/user'
import { Bookmark } from '../../types/bookmark'
import { HomePageNews, NewsFacets, Rns } from '../../types/rnsList'
import { MarketPrice, RisersFallers } from '../../types/company'
import { LoggedInAuthResponse } from '../../services/auth'
import NewsCard from '../../components/molecules/NewsCard/NewsCard'
import NewsFilter from '../../components/molecules/NewsFilter/NewsFilter'
import { Filters } from '../../components/molecules/NewsFilter/NewsFilter'
import { Icon } from '../../components/atoms/Icon/Icon'
import Button from '../../components/atoms/Button/Button'
import { NewsModel } from '../../components/molecules/NewsModel/NewsModel'
import Footer from '../../components/molecules/Footer/Footer'
import { MarketPanel } from '../../components/organisms/MarketPanel/MarketPanel'
import SignInRegisterModal from '../../components/molecules/SignInRegisterModal/SignInRegisterModal'
import TabMenu from '../../components/molecules/TabMenu/TabMenu'
import { Spinner, ArrowDown, ArrowUp, BookmarkSelected, Trending } from '../../components/atoms/Icons/Icons'
import RisersFallersList from '../../components/molecules/RisersFallersList/RisersFallersList'
import './index.css'
import { TrendingDown, TrendingUp } from 'lucide-react'

interface HomeViewProps {
	authResult?: LoggedInAuthResponse
}

interface ReadNewsItem {
	id: string
	timestamp: number
}

const filterKey = 'homeFilters'
const readNewsKey = 'readNewsItems'

const HomeView: React.FC<HomeViewProps> = ({ authResult }) => {
	const [news, setNews] = useState<HomePageNews[]>([])
	const [risersFallers, setRisersFallers] = useState<RisersFallers>()
	const [risersFallersTabSelected, setRisersFallersTabSelected] = useState<'risers' | 'fallers'>('risers')

	const [bookmarkedNews, setBookmarkedNews] = useState<HomePageNews[]>([])
	const [newsFacets, setNewsFacets] = useState<NewsFacets>()
	const [showBackToTopButton, setShowBackToTopButton] = useState(false)
	const [loading, setLoading] = useState(false)
	const [allContentLoaded, setAllContentLoaded] = useState(false)
	const [newsSelected, setNewsSelected] = useState<Rns | undefined>()
	const [marketPrices, setMarketPrices] = useState<MarketPrice[]>()
	const [page, setPage] = useState(1)
	const [showSignInModal, setShowSignInModal] = useState(false)
	const [isFilterFullScreen, setIsFilterFullScreen] = useState(false)
	const [usersBookmarkSet, setUsersBookmarkSet] = useState<Set<string>>(new Set())
	const [selectedTab, setSelectedTab] = useState<'trending' | 'bookmarks'>('trending')
	const navigate = useNavigate()
	const messagesEndRef = useRef<HTMLDivElement>(null)

	const [filters, setFilters] = useState<Filters>(() => {
		const savedFilters = localStorage.getItem(filterKey)
		return savedFilters
			? JSON.parse(savedFilters)
			: {
					marketCap: {
						large: true,
						mid: true,
						small: true,
						micro: true,
					},
					sentimentOptions: {
						very_bad: true,
						bad: true,
						neutral: true,
						good: true,
						very_good: true,
					},
					todayOnly: false,
			  }
	})

	const [readNewsItems, setReadNewsItems] = useState<Set<string>>(() => {
		const savedReadNews = JSON.parse(localStorage.getItem(readNewsKey) || '[]') as ReadNewsItem[]
		const twoWeeksAgo = Date.now() - 14 * 24 * 60 * 60 * 1000
		const validReadNews = savedReadNews.filter((item) => item.timestamp >= twoWeeksAgo)
		localStorage.setItem(readNewsKey, JSON.stringify(validReadNews))
		return new Set(validReadNews.map((item) => item.id))
	})

	// Memoized function to count active filters
	const activeFilterCount = useMemo(() => {
		const marketCapCount = Object.values(filters.marketCap).filter(Boolean).length
		const sentimentCount = Object.values(filters.sentimentOptions).filter(Boolean).length
		return marketCapCount + sentimentCount
	}, [filters])

	// Memoize the handleFilterChange function
	const handleFilterChange = useCallback(
		(newFilters: Filters) => {
			setFilters(newFilters)
			setNews([])
			setPage(1)
			setAllContentLoaded(false)
		},
		[], // No dependencies since we're using state setters which are stable
	)

	// Adjusted loadNews function
	const loadNews = useCallback(async () => {
		if (loading || allContentLoaded) return
		setLoading(true)
		try {
			localStorage.setItem(filterKey, JSON.stringify(filters))
			const resp = await getHomePageNews(page, filters)
			if (page > 1) {
				setNews((currentNews) => [...currentNews, ...resp])
			} else {
				setNews(resp)
			}
			setAllContentLoaded(resp.length < 20)
		} catch (error) {
			console.error('Failed to load news:', error)
		} finally {
			setLoading(false)
		}
	}, [page, filters]) // Removed loading and allContentLoaded from dependencies

	// Adjusted loadBookmarks function
	const loadBookmarks = useCallback(() => {
		getUserBookmarks().then((resp) => {
			const bookmarkIds = new Set(resp.map((bookmark) => bookmark.rnsId))
			setUsersBookmarkSet(bookmarkIds)
			getNewsItems(resp).then(setBookmarkedNews)
		})
	}, []) // Empty dependency array

	// useEffect to load data when selectedTab changes
	useEffect(() => {
		if (selectedTab === 'trending') {
			loadNews()
		} else {
			loadBookmarks()
		}
	}, [selectedTab, loadNews, loadBookmarks])

	// Load initial data
	useEffect(() => {
		getMarketPrices().then(setMarketPrices)
		if (authResult?.auth_type === 'registered') {
			loadBookmarks()
		}
		getRisersFallers().then(setRisersFallers)
	}, [authResult, loadBookmarks])

	// Scroll event handler
	useEffect(() => {
		const handleScroll = () => {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop
			setShowBackToTopButton(scrollTop > 300)
		}
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	// Prevent body scroll when modal is open
	useEffect(() => {
		if (newsSelected || showSignInModal) {
			document.body.classList.add('no-scroll')
		} else {
			document.body.classList.remove('no-scroll')
		}
	}, [newsSelected, showSignInModal])

	// Infinite scrolling
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				const [entry] = entries
				if (entry.isIntersecting && !loading && !allContentLoaded) {
					if (selectedTab === 'trending') {
						setPage((prevPage) => prevPage + 1)
					}
				}
			},
			{
				root: null,
				rootMargin: '0px',
				threshold: 1.0,
			},
		)

		if (messagesEndRef.current) observer.observe(messagesEndRef.current)

		return () => observer.disconnect()
	}, [loading, allContentLoaded, selectedTab])

	// Handle news item click
	const newsClick = (rns: Rns) => {
		setNewsSelected(rns)
		updateReadNewsItems(rns.id)
	}

	// Update read news items
	const updateReadNewsItems = (id: string) => {
		setReadNewsItems((prev) => {
			const updated = new Set(prev)
			updated.add(id)
			const savedReadNews = JSON.parse(localStorage.getItem(readNewsKey) || '[]') as ReadNewsItem[]
			const twoWeeksAgo = Date.now() - 14 * 24 * 60 * 60 * 1000
			const validReadNews = savedReadNews.filter((item) => item.timestamp >= twoWeeksAgo)
			validReadNews.push({ id, timestamp: Date.now() })
			localStorage.setItem(readNewsKey, JSON.stringify(validReadNews))
			return updated
		})
	}

	// Handle bookmark click
	const bookmarkClick = (newsItem: Bookmark, isBookmarked: boolean) => {
		if (authResult?.auth_type === 'registered') {
			const bookmarkPromise = isBookmarked ? removeBookmark(newsItem) : addBookmark(newsItem)
			bookmarkPromise.then(loadBookmarks)
			return true
		} else {
			setShowSignInModal(true)
			return false
		}
	}

	// Handle navigation to company
	const handleNavigateToCompany = (ticker: string) => {
		navigate(`/company/lse/${ticker.toLowerCase()}`)
	}

	// Handle news modal close
	const handleNewsModelClose = () => {
		setNewsSelected(undefined)
	}

	// Handle filter open/close
	const handleFilterOpen = () => {
		setIsFilterFullScreen((prevState) => !prevState)
	}

	// Handle tab click
	const handleTabClick = (tabId: string) => {
		setSelectedTab(tabId as 'trending' | 'bookmarks')
	}

	// Handle risers/fallers tab selection
	const handleClickRiserFaller = (ticker: string) => {
		navigate(`/company/lse/${ticker}`)
	}

	// Greeting message
	const getGreetingMessage = () => {
		const now = new Date()
		const formatter = new Intl.DateTimeFormat('en-GB', {
			timeZone: 'Europe/London',
			hour: 'numeric',
			hour12: false,
		})
		const hour = parseInt(formatter.format(now), 10)

		if (hour < 12) {
			return 'Good morning'
		} else if (hour < 18) {
			return 'Good afternoon'
		} else {
			return 'Good evening'
		}
	}

	// Render news items
	const renderNewsItems = () => {
		return news.map((n: HomePageNews, index: number) => {
			const isSecondLast = index === news.length - 2
			return (
				<div className='pt-1' key={n.news.id} ref={isSecondLast ? messagesEndRef : null}>
					<NewsCard
						news={n.news}
						showPrice
						showCompanyDetail
						onClick={newsClick}
						authResult={authResult}
						isBookmarked={usersBookmarkSet.has(n.news.id)}
						onBookmarkClick={bookmarkClick}
						isRead={readNewsItems.has(n.news.id)}
					/>
				</div>
			)
		})
	}

	return (
		<div className='flex flex-col font-sans text-white bg-msDark overflow-y-scroll w-screen pt-[80px] z-0 scroll'>
			{isFilterFullScreen && (
				<div className='xl:hidden flex'>
					<div className='fixed top-10 left-0 w-full h-full z-40 bg-black bg-opacity-50 backdrop-blur-sm'></div>
					<div className='fixed left-0 top-10 w-full z-50 bg-msDark justify-start px-5 pt-10'>
						<div className='xl:hidden flex flex-col justify-between'>
							<div>
								<span className='text-2xl font-bold'>filters.</span>
								<hr className='border-0 bg-white bg-opacity-50 rounded-xl h-[1px] w-full' />
								<NewsFilter onFilterChange={handleFilterChange} filters={filters} newsFacets={newsFacets} />
							</div>
							<div className='pb-6'>
								<Button text='Apply' onClick={handleFilterOpen} className=' bg-mslight w-full hover:bg-msbright font-semibold rounded-md p-5' />
							</div>
						</div>
					</div>
				</div>
			)}
			<div className='flex flex-row'>
				<div className='xl:w-1/4 xl:flex hidden' />
				<div className='flex flex-col xl:w-1/2 w-full'>
					<div className='text-3xl font-bold w-full pl-3 xl:pl-0 py-2'>
						{authResult?.user?.firstName && (
							<>
								{getGreetingMessage()}, {authResult.user.firstName}!
							</>
						)}
					</div>
					<div className='flex w-full'>
						<MarketPanel marketPrices={marketPrices} />
					</div>
					<div className='flex w-full px-3 pt-3 xl:px-0'>
						<TabMenu
							tabs={[
								{ id: 'trending', label: 'trending news', icon: <Trending /> },
								{
									id: 'bookmarks',
									label: 'bookmarks',
									counter: bookmarkedNews?.length,
									disabled: authResult?.auth_type !== 'registered',
									icon: <BookmarkSelected />,
								},
							]}
							onTabClick={handleTabClick}
						/>
					</div>
					{selectedTab === 'trending' && (
						<div className='pt-3 pr-4 xl:hidden flex h-full w-full justify-end'>
							<button className='flex flex-row items-center cursor-pointer hover:no-underline text-base font-light tracking-wide' onClick={handleFilterOpen}>
								<Icon className='mr-2' type='filter' />
								<span className='underline h-full font-semibold'>Filters</span>
								{activeFilterCount > 0 && (
									<span className='ml-1 inline-flex items-center justify-center px-2 py-1 leading-none text-white bg-red-600 rounded-full text-sm'>{activeFilterCount}</span>
								)}
							</button>
						</div>
					)}
				</div>
				<div className='xl:w-1/4 xl:flex hidden' />
			</div>
			<div className='flex flex-row'>
				<div className='hidden xl:flex xl:w-1/4 w-0 justify-end'>
					<div className='flex w-1/2 pt-4'>
						<div className='flex flex-col w-full'>
							<div className='mr-3 shadow-lg bg-mslight rounded-lg border-2 border-gray-700'>
								<NewsFilter onFilterChange={handleFilterChange} filters={filters} newsFacets={newsFacets} />
							</div>
						</div>
					</div>
				</div>
				<div className='xl:w-1/2 w-full gap-4 flex flex-col px-2 min-h-screen pt-3'>
					{selectedTab === 'trending' ? (
						<div className='flex gap-3 flex-col'>
							{loading && page === 1 ? Array.from({ length: 8 }).map((_, index) => <NewsCard key={index} loading={true} />) : renderNewsItems()}
						</div>
					) : !bookmarkedNews || bookmarkedNews.length === 0 ? (
						<div className='text-center p-4 pt-5 text-gray-400 text-lg font-light'>No bookmarks yet!</div>
					) : (
						bookmarkedNews.map((n: HomePageNews) => (
							<div key={n.news.id}>
								<NewsCard
									news={n.news}
									showPrice
									showCompanyDetail
									onClick={newsClick}
									authResult={authResult}
									isBookmarked={usersBookmarkSet.has(n.news.id)}
									onBookmarkClick={bookmarkClick}
								/>
							</div>
						))
					)}
				</div>
				<div className='hidden xl:flex xl:w-1/4 w-0 justify-start pl-3 pr-20 pt-4'>
					<div className='flex w-full'>
						<div className='flex flex-col w-full'>
							<div className='flex flex-col w-full '>
								<TabMenu
									tabs={[
										{ id: 'risers', label: 'risers', icon: <TrendingUp className='w-4 h-4' /> },
										{ id: 'fallers', label: 'fallers', icon: <TrendingDown className='w-4 h-4' /> },
									]}
									onTabClick={(tabId) => setRisersFallersTabSelected(tabId as 'risers' | 'fallers')}
								/>
								<RisersFallersList risersFallers={risersFallers} risersFallersTabSelected={risersFallersTabSelected} handleClickRiserFaller={handleClickRiserFaller} />
							</div>
						</div>
					</div>
				</div>
			</div>
			{showBackToTopButton && (
				<button
					className='flex items-center justify-center fixed bottom-10 left-1/2 transform -translate-x-1/2 bg-msDark rounded-full w-12 h-12 text-white text-xl focus:outline-none'
					onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
				>
					<div className=''>{loading ? <Spinner className='w-full h-full p-1' /> : <Icon type='arrow_up' />}</div>
				</button>
			)}
			<div className='xl:w-1/4 xl:flex hidden' />
			{newsSelected && (
				<NewsModel
					isOpen={newsSelected !== undefined}
					title={newsSelected?.generatedData ? newsSelected.generatedData.headline : newsSelected?.headline}
					originalTitle={newsSelected?.headline}
					url={newsSelected?.url}
					dateTime={newsSelected?.date ? newsSelected?.date : undefined}
					longSummary={newsSelected?.generatedData ? newsSelected?.generatedData.longSummary : newsSelected?.headline}
					bulletPoints={newsSelected?.generatedData ? newsSelected?.generatedData.bulletPoints : undefined}
					handleClose={handleNewsModelClose}
					newsId={newsSelected?.id}
					ticker={newsSelected?.company.ticker}
					companyIndustry={newsSelected?.company?.industry}
					companyMarketCap={newsSelected?.company?.marketCap}
					companyMarketCapDisp={newsSelected?.company.marketCapDisp}
					keyDates={newsSelected?.generatedData?.keyDates}
					sentimentScore={newsSelected?.generatedData?.sentimentScore}
					sentimentReasoning={newsSelected?.generatedData?.sentimentReasoning}
					handleNavigateToCompany={newsSelected?.company?.ticker ? () => handleNavigateToCompany(newsSelected.company.ticker) : undefined}
					financials={newsSelected?.generatedData?.financials}
					isBookmarked={usersBookmarkSet.has(newsSelected.id ?? '')}
					onBookmarkClick={bookmarkClick}
					id={newsSelected?.id}
				/>
			)}
			<div className='h-[100px]'>
				<Footer />
			</div>
			{showSignInModal && <SignInRegisterModal isOpen={showSignInModal} onClose={() => setShowSignInModal(false)} />}
		</div>
	)
}

export default HomeView
