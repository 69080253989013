import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getCompanyNews } from '../../services/rns'
import { Rns } from '../../types/rnsList'
import { NewsModel } from '../../components/molecules/NewsModel/NewsModel'
import { useNavigate } from 'react-router-dom'
import { mapMarketCap } from '../../utils/company'
import { ProgressSpinner } from '../../components/atoms/ProgressSpinner'
import { Icon } from '../../components/atoms/Icon/Icon'

const NewsView = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const { market = '', ticker = '', id = '' } = useParams()
	const [newsItem, setNewsItem] = useState<Rns>()
	const navigate = useNavigate()

	useEffect(() => {
		setLoading(true)
		getCompanyNews(market, ticker, id).then((res: Rns | undefined) => {
			if (!res || !res.company) return // 404
			setLoading(false)
			setNewsItem(res)
		})
	}, [id])

	const handleNavigateToCompany = (ticker: string) => {
		navigate(`/company/lse/${ticker.toLowerCase()}`)
	}

	if (loading) {
		return (
			<div className=' h-screen bg-msDark '>
				<ProgressSpinner className=' text-white' isPage={true} size='large' />
			</div>
		)
	}

	return (
		<div className='flex h-screen overflow-auto font-sans text-white bg-msDark'>
			<div className=''>
				{
					<NewsModel
						isOpen={true}
						title={newsItem?.generatedData ? newsItem.generatedData.headline : newsItem?.headline}
						originalTitle={newsItem?.headline}
						url={newsItem?.url}
						dateTime={newsItem?.date ?? undefined}
						longSummary={newsItem?.generatedData ? newsItem?.generatedData.longSummary : newsItem?.headline}
						bulletPoints={newsItem?.generatedData ? newsItem?.generatedData.bulletPoints : undefined}
						handleClose={handleNavigateToCompany}
						newsId={newsItem?.id}
						ticker={newsItem?.company.ticker}
						companyIndustry={newsItem?.company?.industry}
						companyMarketCap={newsItem?.company?.marketCap}
						companyMarketCapDisp={newsItem?.company?.marketCapDisp}
						keyDates={newsItem?.generatedData?.keyDates}
						sentimentScore={newsItem?.generatedData?.sentimentScore}
						sentimentReasoning={newsItem?.generatedData?.sentimentReasoning}
						companyDetail={newsItem?.generatedData?.companyDetail}
						handleNavigateToCompany={newsItem?.company?.ticker ? () => handleNavigateToCompany(newsItem.company.ticker) : undefined}
						hideCloseButton={true}
						showHomeButton={true}
						financials={newsItem?.generatedData?.financials}
						id={newsItem?.id}
					/>
				}
			</div>
		</div>
	)
}

export default NewsView
