'use client'
import { useNavigate } from 'react-router-dom'
import SearchBar from '../SearchBar/SearchBar'
import { Icon } from '../../atoms/Icon/Icon'
import { useEffect, useState, useRef } from 'react'
import Button from '../../atoms/Button/Button'
import { MobileMenu } from '../../organisms/MobileMenu/MobileMenu'
import { LoggedInAuthResponse } from '../../../services/auth'
import UserMenu from '../UserMenu/UserMenu'
import { Cross as Hamburger } from 'hamburger-react'
import { TwitterX } from '../../atoms/Icons/Icons'

export interface HeaderProps {
	setIsMobileSearchOpen: (isMobileSearchOpen: boolean) => void
	authResult?: LoggedInAuthResponse
	className?: string
}

export default function Header(props: HeaderProps) {
	const navigate = useNavigate()
	const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false)
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
	const [loggedIn, setLoggedIn] = useState(false)
	const [isScrolled, setIsScrolled] = useState(false)
	const menuRef = useRef<HTMLDivElement>(null)
	const buttonRef = useRef<HTMLButtonElement>(null)

	const goHome = () => {
		navigate('/')
	}

	useEffect(() => {
		setLoggedIn(props.authResult?.auth_type === 'registered')
	}, [props.authResult])

	const handleIsMobileSearchOpen = () => {
		setIsMobileSearchOpen(!isMobileSearchOpen)
		props.setIsMobileSearchOpen(!isMobileSearchOpen)
	}

	const handleOpenDiscord = () => {
		window.open('https://discord.gg/HPjw34vvYJ', '_blank')
	}

	const handleOpenTwitter = () => {
		window.open('https://x.com/marketshareai', '_blank')
	}

	const handleSignup = () => {
		window.location.href = `/signup?forward_url=${encodeURI(window.location.href)}`
	}

	const handleLogin = () => {
		window.location.href = `/login?forward_url=${encodeURI(window.location.href)}`
	}

	useEffect(() => {
		const handleScroll = () => {
			setIsScrolled(window.scrollY > 50)
		}

		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (menuRef.current && !menuRef.current.contains(event.target as Node) && buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
				setIsMobileMenuOpen(false)
			}
		}

		if (isMobileMenuOpen) {
			document.addEventListener('mousedown', handleClickOutside)
		} else {
			document.removeEventListener('mousedown', handleClickOutside)
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isMobileMenuOpen])

	return (
		<header
			className={`bg-msDark/70 fixed top-0 left-1/2 transform -translate-x-1/2 backdrop-blur transition-all duration-500 border-white/30 ${
				isScrolled ? 'w-full md:w-3/4 xl:mt-1 md:border border-white/10 md:border-b-[1px] shadow-lg md:rounded-xl' : 'w-full h-16'
			} md:px-8 lg:px-38`}
		>
			<nav>
				<div className={`px-4 relative flex justify-between items-center py-3 transition-all ${isScrolled ? '' : ''}`}>
					<div className='flex items-center justify-between xl:w-1/3 w-full'>
						<button onClick={goHome} className='h-full'>
							<Icon type='logo' className='w-auto' />
						</button>
						<div className='xl:hidden flex justify-end w-full h-full gap-2 text-white'>
							<button onClick={() => handleIsMobileSearchOpen()}>
								{isMobileSearchOpen ? <Icon type={'cross'} className='flex w-[30px] h-[30px]' /> : <Icon type={'search'} className='flex w-[30px] h-[30px]' />}
							</button>
							<button ref={buttonRef} className='flex z-50' onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
								<Hamburger toggled={isMobileMenuOpen} toggle={setIsMobileMenuOpen} />
							</button>
						</div>
					</div>
					{/* Hide SearchBar on medium screen when scrolled */}
					<div className={`hidden xl:flex items-center flex-1 justify-center ${isScrolled ? 'md:hidden' : ''}`}>
						<div className='w-full max-w-[600px]'>
							<SearchBar onClick={undefined} />
						</div>
					</div>
					<div className='hidden xl:flex items-center gap-5 w-auto lg:w-1/3 justify-end'>
						{!loggedIn ? (
							<div className='flex flex-row gap-2'>
								<Button
									className='text-slate-300  font-extrabold text-sm px-4 py-2 transition-all duration-75 ease-in-out hover:scale-110'
									text='Sign up.'
									onClick={handleSignup}
								/>
								<Button
									className='rounded-3xl whitespace-nowrap font-extrabold bg-green-400 text-msMedium text-sm px-4 py-2 duration-75 ease-in-out hover:scale-110'
									text='Log in.'
									onClick={handleLogin}
								/>
							</div>
						) : (
							<UserMenu authResult={props.authResult} />
						)}
						<div className='flex gap-2'>
							<button className='transition-all duration-75 ease-in-out hover:scale-125 hover:shadow-lg p-1' onClick={handleOpenTwitter}>
								<div className=' text-slate-300 w-[22px] h-[22px]'>
									<TwitterX />
								</div>
							</button>
							<button className='transition-all duration-75 ease-in-out hover:scale-125 hover:shadow-lg p-1' onClick={handleOpenDiscord}>
								<Icon type='social_discord' className='w-[29px] h-[29px] text-slate-300' />
							</button>
						</div>
					</div>
				</div>
				{isMobileSearchOpen && (
					<div className='xl:hidden bg-msDark transition-all h-12 w-full'>
						<SearchBar onClick={() => handleIsMobileSearchOpen()} mobileOpen={isMobileSearchOpen} className='px-2 mx-auto text-white' />
					</div>
				)}
				{/* Mobile Menu */}
				<MobileMenu isOpen={isMobileMenuOpen} loggedIn={loggedIn} closeMenu={() => setIsMobileMenuOpen(false)} />
			</nav>
		</header>
	)
}
